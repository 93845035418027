import axios, { AxiosResponse } from 'axios';
import { JSEncrypt } from 'jsencrypt';
import CryptoJS from 'crypto-js';
import forge from 'node-forge'

interface ApiServiceConfig {
  isSecurity: boolean;
  domain: string;
  privateKey: string;
  publicKey: string;
  appId: string;
}

class ApiService {
  private isSecurity: boolean;
  private domain: string;
  private privateKey: string;
  private publicKey: string;
  private appId: string;

  constructor(config: ApiServiceConfig) {
    this.isSecurity = config.isSecurity;
    this.domain = config.domain;
    this.privateKey = config.privateKey;
    this.publicKey = config.publicKey;
    this.appId = config.appId;
  }

  async BitcakeApi(url: string, method: string = 'POST', payload: any = {}) {
    if (this.isSecurity) {
      return this.requestSecurity(url, method, payload);
    } else {
      return this.request(url, method, payload);
    }
  }

  public async  postWithEncryption  (
    path: string,

     method: string = 'POST',
    body: any,
  ) {
    const source = axios.CancelToken.source()
    setTimeout(() => {
      source.cancel()
    }, 30000)
    try {
      const encryptKey = Math.floor(Math.random() * 10000001)?.toString() ?? '10'
      const key = forge.pki.publicKeyFromPem(this.publicKey)
      const encrypt = key.encrypt(encryptKey, 'RSA-OAEP')
      const xAPIKey = forge.util.encode64(encrypt)
      const xAPIAction = CryptoJS.AES.encrypt(path, encryptKey).toString()
      const xAPIMessage = CryptoJS.AES.encrypt(JSON.stringify(body), encryptKey).toString()
      const md = forge.md.md5.create()
      md.update(`${xAPIAction}${method}${xAPIMessage}${encryptKey}`)
      const xAPIValidate = md.digest().toHex()
  
      const response: AxiosResponse = await axios.request({
        url: this.domain + path,
        method: method,
        data: {
          'x-api-message': xAPIMessage,
        },        
        timeout: 30000,
        headers: {
          "lang": 'vi',
          'x-api-client': this.appId,
          'x-api-key': xAPIKey,
          'x-api-action': xAPIAction,
          'x-api-validate': xAPIValidate,
        },
        cancelToken: source.token,
      })
  
      if (response?.status === 200) {
        const headers = response?.headers ?? {}
        const privateK = forge.pki.privateKeyFromPem(this.privateKey)
        const encrypted = forge.util.decode64(headers['x-api-key'])
        const encryptK = privateK.decrypt(encrypted, 'RSA-OAEP')
        const decrypted = JSON.parse(
          CryptoJS.AES.decrypt(response?.data['x-api-message'], encryptK).toString(CryptoJS.enc.Utf8),
        )
        return decrypted
      } else {
        throw { message: 'Có lỗi xảy ra' }
      }
    } catch (err) {
      console.log('[ERROR]', body, err)
     
    }
  }
  public async request(url: string, method: string = 'POST', payload: any = {}) {
    try {
      const response = await axios({
        url: this.domain + url,
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        data: payload,
      });

      return response.data;
    } catch (error) {
      console.error('Error during request:', error);
      throw error;
    }
  }

  public async requestSecurity(url: string, method: string = 'POST', payload: any = {}) {
    try {
      const encryptKey = Math.floor(10000000 + Math.random() * 90000000).toString();

      const rsa = new JSEncrypt();
      rsa.setPublicKey(this.publicKey);
      const xAPIKey = btoa(rsa.encrypt(encryptKey) || '');

      const encryptedPayload = this.encryptPayload(payload, encryptKey);

      const response = await axios({
        url: this.domain + url,
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': xAPIKey,
          'x-api-client': this.appId,
        },
        data: encryptedPayload,
      });
      console.log('response', response);
      return this.decryptResponse(response.data, encryptKey);
    } catch (error) {
      console.error('Error during secure request:', error);
      throw error;
    }
  }

  private encryptPayload(payload: any, encryptKey: string): string {
    try {
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(payload), // payload as string
        CryptoJS.enc.Utf8.parse(encryptKey), // encryption key as UTF-8 parsed string
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
      ).toString();
      return ciphertext;
    } catch (error) {
      console.error('Error encrypting payload:', error);
      throw error;
    }
  }

  private decryptResponse(responseData: any, encryptKey: string): any {
    try {
      const bytes = CryptoJS.AES.decrypt(
        responseData, // Encrypted response
        CryptoJS.enc.Utf8.parse(encryptKey), // encryption key
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
      );
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData); // Parse the decrypted JSON string into an object
    } catch (error) {
      console.error('Error decrypting response:', error);
      throw error;
    }
  }
}

export default ApiService;
