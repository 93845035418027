// export const appId = '692172';
// export const privateKey = `-----BEGIN RSA PRIVATE KEY-----
// MIIBOAIBAAJAYXGN0mJM3HQlgZgwLPTtXUwJ+yFygF2aSKzJgiOkHwy4dV6GOXpx
// B1T53SiPkjMCSP6ZGlQQdEEsxFcZwOY3mQIDAQABAkALRNGM4fdXi0sHGK2BlXWZ
// 04BX/X87XxfHjcvj8/E38eCU8J05JglbYmUiCsdBzczLaQM4/L3Gp372nylrtfCN
// AiEAu4oOqhlm+yexO9SuVc03ussnIoh5v1JE4dS1DaEl6BMCIQCFA9ySVFIzuRL0
// TIInPVzgS0h+xxZhovkHenRYS/QvIwIgaBzhvT7y89V6SCvvSMTv/mxP4pT/ed5T
// TUgH53QiFi8CIHVjmRATFpDLLu0ncF4NC6BvEEXCkgANAlYVPgSG07OdAiAbHAdK
// UObSheWL2Iry7Xcb215zG+TzUgECc/5HlkFv4A==
// -----END RSA PRIVATE KEY-----`;

// export const publicKey =`-----BEGIN PUBLIC KEY-----
// MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAN6y1FdUOlLd73MMWlaActfjwOOqZ1Pq
// ZqxzmwD4FtAmlOp7LdyeU3qHlvphYN/ca7d5VAq6wYv/pMdOVQKx4z8CAwEAAQ==
// -----END PUBLIC KEY-----`;
export const appId = 'BITCAKE_WEB';

export const privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIBOAIBAAJAYQrmrEWzzz9KJ9l+bNj0MHRf0sRqtGkHzF6CQwmWVG1FPNxCzWHL
wnX+nSVw7jBby9cALNVgEDg4n0sEi4CTEQIDAQABAkAcp1JbNa2VGTP1NkQdMaZQ
lFwi6Lo1EPorfxBpaPBJdydNWcsOHorBipTIFUx/9BsKjnkQ9m6hMGklV39Q5i6t
AiEAwLzy4D5DS828DcVvI6fuZN85WL0nUSZNK+cPw0DDrdMCIQCA5QWZAxDjdzBr
BQIVseIXifiwlczVwUb5wItPXNeZCwIgda1n5T5evFO68/WN7AlGZtRcITAsZ0Gp
cJq9ZwuN7f0CIFHsv1UU/MwHOtAa28tcR988Q8RBQnNuqKUfu6wSz7onAiAvjjvT
xHcdA42Je1XSnG2zZCW+iQM+SlWjCmtsnkCiag==
-----END RSA PRIVATE KEY-----`;

export const publicKey =`-----BEGIN PUBLIC KEY-----
MFswDQYJKoZIhvcNAQEBBQADSgAwRwJAeoqltwr03+TtefPKcZFc0/Tfz5uUhMXl
fWSy2MhkVFAZN9e/lhYsAXyaSxVnFd1a+rI1qvbAk7ywhS1Cl7/1lwIDAQAB
-----END PUBLIC KEY-----`;
export const baseURL = 'https://apix.bitcake.vn'

