import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { QRCode } from "react-qrcode-logo";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCopy } from 'react-icons/fa';
import napas247Icon from "../images/napas247.png";
import vietQRIcon from "../images/vietqr.png";
import { QRPay } from 'vietnam-qr-pay';
import CryptoJS from 'crypto-js';
import { FaCheckCircle } from "react-icons/fa";
import ApiService from '../util/ApiService';
//import { appId, baseURL, privateKey, publicKey } from './common/constant_sbx';

import { appId, privateKey,baseURL,  publicKey } from './common/constant';

const apiServiceConfig = {
  isSecurity: true,
  domain: baseURL,
  privateKey: privateKey,
  publicKey: publicKey,
  appId: appId,
};

const apiService = new ApiService(apiServiceConfig);

const PaymentLinkPage: React.FC = () => {
  const [paymentData, setPaymentData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [purpose, setPurpose] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const transactionId = queryParams.get('transactionId');
  const [countdown, setCountdown] = useState<number>(5);

  const fetchPaymentData = async () => {
    try {
      setIsLoading(true);
      const payload = { transactionId: transactionId };
     
      const response = await apiService.postWithEncryption('/payment/detail-query', 'POST', payload);
      console.log('response:', response);

      if (response.code === 143000) {
        setPaymentData(response.data);
        if (response.data.state !== 'SUCCEEDED') {
          const qrPayShop = new QRPay(response.data.payData.qrCode);
          if (qrPayShop.isValid) {
            setPurpose(qrPayShop.additionalData.purpose ?? '');
          }
        }
        
        setError(null); // Clear any previous errors
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError('Error fetching payment data');
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (transactionId !== null && transactionId !== '') {
      fetchPaymentData();
      const interval = setInterval(fetchPaymentData, 10000); // Call API every 5 seconds
      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [transactionId]);

  useEffect(() => {
    if (paymentData?.state === 'SUCCEEDED') {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            window.location.href = paymentData.redirectUrl;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [paymentData]);
 

  if (transactionId === null || transactionId === '') {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="text-red-500">Yêu cầu tải thông tin thanh toán không hợp lệ!</div>
      </div>
    );
  }

  
  if (error) {
    return (
      <div className="justify-center items-center min-h-screen bg-gray-100 py-8">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }
  if (paymentData === null) {
    return (<></>);
  }
  return (
    <>
    {paymentData.state === 'SUCCEEDED' ? 
    <div className="justify-center items-center min-h-screen bg-white">
      <div
        className="relative aspect-video w-full rounded-b-2xl pt-8 pb-4"
        style={{
          backgroundColor: "#008700",
          height:240
        }}
      >
        <h1 className="font-bold text-center mb-4 text-white">Thanh toán đơn hàng thành công</h1>

      </div>
      <div className='flex justify-center items-center'> 
      <div className="bg-gray-100 shadow-md rounded-lg p-8 pb-8 my-4 mx-1 max-w-lg relative self-center" style={{ marginTop: -160 }}
      >   

        <div className=" p-4 mt-2 mb-2 text-3xl font-bold text-center items-center justify-center">
        <div className=" flex justify-center py-2">

        <FaCheckCircle className='text-green-700' size={45}/>
        </div>

          {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(paymentData.amount)}
        </div>

            <p className="text-green-700 text-center text-sm py-4">Đơn hàng đã thanh toán thành công</p>
            <div className="text-center text-red-500 mt-4 mb-4 text-sm">
                Trang sẽ tự động chuyển đến website mua hàng trong {countdown} giây...
              </div>
            <div className="space-y-6">
            <div className="flex justify-between items-center">
                <span className="text-xs text-gray-700">Mã giao dịch:</span>
                <span className="text-xs font-bold">{paymentData.transactionId}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-xs text-gray-700">Người thanh toán :</span>
                <span className="text-xs font-bold">{paymentData.remitterInfo?.fullName}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-xs">Tài khoản thanh toán:</span>
                <span className="text-xs font-bold ">{paymentData.remitterInfo?.number}</span>
              </div>

              <div className="flex justify-center items-center">
                <span className="text-xs">Nội dung thành toán</span>
                </div>

              <div className="justify-between items-center">
                  <span className="text-xs font-bold ">{paymentData.remitterInfo?.content}</span>                  
              </div>
            </div>
       
      </div>
      </div>
    </div> :<div className="justify-center items-center min-h-screen bg-white">
      <div
        className="relative aspect-video w-full rounded-b-2xl pt-8 pb-4"
        style={{
          backgroundColor:"#0033CB",
          height:240
        }}
      >
        <h1 className="font-bold text-center mb-4 text-white">Thanh toán đơn hàng</h1>

      </div>
      <div className='flex justify-center items-center'> 
      
      <div className="bg-gray-100 shadow-md rounded-lg p-2 m-4 max-w-lg relative" style={{ marginTop: -160 }}
      >   

        <div className=" p-4 mt-4 mb-2 text-3xl font-bold text-center ">
          {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(paymentData.amount)}
        </div>

        
          <div>
            <p className="text-gray-500 text-center text-sm mb-4">Quét mã QR để thanh toán, hoặc chuyển khoản theo nội dung bên dưới</p>

            {/* QR Code */}
            <div className="flex justify-center mb-4">
              <QRCode
                value={paymentData.payData.qrCode}
                size={220}
                qrStyle={"squares"}
                style={{ border: "1px solid #f3f4f6",width: "75%", height: "auto" }}
              />
            </div>
            <div className="flex justify-between space-x-3 mx-3">
              <div className="flex w-1/3 justify-center m-2">
                <img src={napas247Icon} className="w-full h-auto" />
              </div>
              <div className="flex w-1/3 justify-center m-2">
                <img src={vietQRIcon} className="w-full h-auto" />
              </div>
            </div>
            <hr className="my-4 border-gray-300" />

            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <span className="text-xs text-gray-700">Ngân Hàng :</span>
                <span className="text-xs font-bold">{paymentData.payData?.bankName}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-xs">Tên tài khoản :</span>
                <span className="text-xs font-bold ">{paymentData.payData?.accountName}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-xs">Số tài khoản :</span>
                  <span className="text-xs font-bold ">{paymentData.payData?.accountNumber}</span>
                
              </div>
              <div className="flex justify-between items-center">
                <span className="text-xs">Nội dung chuyển khoản:</span>
                  <span className="text-xs font-bold ">{purpose}</span>                  
              </div>
            </div>
            <ul className="mt-6 text-xs  space-y-2 text-left">
              <li>1. Sử dụng ứng dụng ngân hàng của bạn để quét mã QR để hoàn tất chuyển khoản nhanh chóng!!!</li>
              <li>2. Vui lòng nhất định phải điền Nội Dung CK <span className="text-red-500">{purpose}</span> phía trên khi chuyển khoản.</li>
              <li>3. Nếu không điền đủ thông tin nội dung chuyển khoản, giao dịch của bạn sẽ phải chờ để được xử lý.</li>
            </ul>
          </div>
        
      </div>
      </div>
    </div>}
    </>
  );
};

export default PaymentLinkPage;